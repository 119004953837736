<script>
// Extensions
import View from '@/views/View'

// Mixins
import LoadSections from '@/mixins/load-sections'

export default {
  name: 'FourOhFour',

  metaInfo() {
    return {
      title: this.$t('page_not_found'),
      meta: [
        { name: 'prerender-status-code', content: '404' },
      ],
    };
  },

  extends: View,

  mixins: [
    LoadSections([
      '404',
      'info',
    ]),
  ],

  props: {
    id: {
      type: String,
      default: 'about',
    },
  },
}
</script>
